import React from 'react';
import CloseIcon from '@mui/icons-material/Close'; // Import the Close icon

const Alert = ({ title, description, buttons, onButtonClick, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-80">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-lg font-bold">{title}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <CloseIcon /> {/* Close icon from Material-UI */}
          </button>
        </div>
        <p className="mb-4 text-red">{description}</p>
        <div className="flex justify-end">
          
          {/* Action Buttons */}
          {buttons.map((button, index) => (
            <button
              key={index}
              onClick={() => onButtonClick(button.value)}
              className="bg-customPurple hover:bg-customPurple text-white font-semibold py-2 px-4 rounded mr-2"
            >
              {button.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Alert 