import React from 'react';
import { useUser } from '../contexts/UserContext';
import { Link, useNavigate } from 'react-router-dom';

const ProfileScreen = () => {
  const { user, clearUser } = useUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    clearUser();
    navigate('/');
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center p-4">
      <div className="bg-white shadow-xl rounded-lg p-8 w-full max-w-lg mb-8">
        <div className="flex flex-col items-center">
          <img
            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(user?.fullName)}&background=0D8ABC&color=fff&size=128`}
            alt="Profile"
            className="w-32 h-32 rounded-full mb-4"
          />
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">{user?.fullName}</h2>
          <p className="text-gray-500 mb-1">{user?.email}</p>
          <p className="text-gray-500 mb-1">{user?.phone}</p>
          <button
            onClick={() => handleLogout()}
            className="bg-red-500 text-white py-2 mt-5 px-6 rounded-full hover:bg-red-600 transition duration-300"
          >
            Logout
          </button>
        </div>
      </div>


         {/* Add Redirection Links */}
      <div className="mt-8 text-center">
        <p className="mb-4">
          <Link to="/about-us" className="text-customPurple hover:underline">
            About Us
          </Link>
        </p>
        <p className="mb-4">
          <Link to="/terms-and-conditions" className="text-customPurple hover:underline">
            Terms & Conditions
          </Link>
        </p>
        <p className="mb-4">
          <Link to="/privacy-policy" className="text-customPurple hover:underline">
            Privacy Policy
          </Link>
        </p>
        <p className="mb-4">
          <Link to="/cancellation-policy" className="text-customPurple hover:underline">
            Cancellation/Refund Policy
          </Link>
        </p>
      </div>

    </div>
  );
};

export default ProfileScreen;
