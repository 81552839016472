import { supabase } from "../client"
import { showToastFailure } from "./toast"

export const getPasses = async (startLoading, stopLoading) => {
    try {
      startLoading()
        const { data, error } = await supabase
        .from('pass')
        .select('*')
        .eq('is_active', true)
        .order('created_at', { ascending: false });
      
      if (error) {
        throw error
      }
  
      return data
    } catch (error) {
      showToastFailure(error.message)
      return []
    }finally {
      stopLoading()
    }
  }

  export const getSubPasses = async (passId, startLoading, stopLoading) => {
    try {
      startLoading()
      const { data, error } = await supabase
      .from('pass_details')
      .select('*')
      .eq('pass_id', passId)
      .eq('is_active', true)
      .order('created_at', { ascending: false });
      
    
    if (error) {
      throw error
    }

    return data
  } catch (error) {
    showToastFailure(error.message)
    return []
  }finally {
    stopLoading();
  }
  }

  export const getGST = async (startLoading, stopLoading) => {
    try {
      startLoading()
      const { data, error } = await supabase
      .from('gst')
      .select('*')
      .eq('is_active', true);
    
    if (error) {
      throw error
    }

    return data
  } catch (error) {
    showToastFailure(error.message)
    return []
  }finally{
    stopLoading()
  }
  }

  export const getRemainingTicketsForSubPasses = async (subPassId) => {
    const { data } = await supabase
    .from('pass_details')
    .select('*')
    .eq('id', subPassId)
    .eq('is_active', true)
    .order('created_at', { ascending: false });

    return data;
  }