import { toast } from 'react-toastify';

const showToastSuccess = (message) => {
  toast.success(message, {
    autoClose: 10000
  });
};
const showToastFailure = (message) => {
  toast.error(message, {
    autoClose: 10000
  });
};

export { showToastSuccess, showToastFailure };
