import React, { useEffect, useState } from 'react';
import { getEvents } from '../services/eventsService';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { useLoading } from '../contexts/LoadingContext';
import PassCard from '../components/PassCard';

const EventsScreen = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useLoading();
  const { user } = useUser();

  const accessEvents = {
    name: 'Access you events',
    description: 'Access your booked events'
  }

  useEffect(() => {
    const fetchEvents = async () => {
      const fetchedEvents = await getEvents(showLoader, hideLoader);
      setEvents(fetchedEvents);
    };

    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEventClick = (event) => {
    if (user) {
      navigate('/event-details', { state: { event } });
    } else {
      navigate('/auth');
      return;
    }
  }

  const onActiveEventsClick = () => {
    navigate('/booked-events');
  }

  return (
    <div>
      {events?.length > 0 && <h2 className="text-2xl font-semibold text-gray-700 mt-10 mb-5">Upcoming Events</h2>}
      <div>
        {user && (
          <div className="grid grid-cols-1 gap-6 w-full max-w-lg">
            <PassCard
              pass={accessEvents}
              onPassClick={onActiveEventsClick}
              bgColor="bg-customPurple"
              textColor="text-white"
              buttonBgColor="bg-white"
              buttonTextColor="text-black"
              btnText="View Tickets"
            />
          </div>
        )}

        <div className="grid grid-cols-1 gap-6 mt-5 mb-10 w-full max-w-lg">
          {events?.map((pass, index) => (
            <PassCard
              key={index}
              pass={pass}
              onPassClick={handleEventClick}
              bgColor="bg-white"
              textColor="text-gray-800"
              buttonBgColor="bg-customPurple"
              buttonTextColor="text-white"
              btnText="Buy Tickets"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventsScreen;