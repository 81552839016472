import { supabase } from '../client';

export const fetchEventsAndImages = async (showLoader, hideLoader, setImages) => {
    try {
        // Check if images are already stored in sessionStorage
        const storedImages = sessionStorage.getItem('bannerImages');
        if (storedImages) {
            setImages(JSON.parse(storedImages)); // Set images from sessionStorage
            return; // Exit if images are found
        }

        showLoader();
        const now = new Date();
        const utcTime = now.toISOString();

        // Fetch event data (including `image_url`)
        const { data: events, error: eventsError } = await supabase
            .from('events')
            .select('id, image_url') // Select event ID and image URL
            .eq('is_active', true)
            .gte('event_end_time', utcTime)
            .order('created_at', { ascending: false });

        if (eventsError) {
            console.error('Error fetching events:', eventsError.message);
            hideLoader();
            return;
        }

        // Fetch list of files from the 'banners' folder inside the 'images' bucket
        const { data: bannerFiles, error: bannersError } = await supabase
            .storage
            .from('images')
            .list('banners'); // Folder path inside the bucket

        if (bannersError) {
            console.error('Error fetching banners:', bannersError.message);
            hideLoader();
            return;
        }

        // Construct event image URLs directly using the environment variable
        const eventBannerUrls = events.map((event) => {
            return event?.image_url
                ? `${process.env.REACT_APP_SUPABASEURL}/storage/v1/object/public/images/events/${event.image_url}?width=100&height=100`
                : null;
        });

        // Construct banner image URLs directly using the environment variable
        const bannerUrls = bannerFiles.map((file) => {
            return file
                ? `${process.env.REACT_APP_SUPABASEURL}/storage/v1/object/public/images/banners/${file.name}?width=100&height=100`
                : null;
        });

        // Combine both arrays of URLs and filter out null values
        const combinedBannerUrls = [...eventBannerUrls, ...bannerUrls].filter(url => url !== null);

        // Set images in sessionStorage
        sessionStorage.setItem('bannerImages', JSON.stringify(combinedBannerUrls));

        // Set the banner URLs in state
        setImages(combinedBannerUrls);
    } catch (err) {
        console.error('Error fetching events and banners:', err);
    } finally {
        hideLoader();
    }
};
