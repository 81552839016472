import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { supabase } from '../client';
import { showToastFailure } from '../services/toast';
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import PasswordResetModal from '../modals/PasswordResetModal';
import { useLoading } from '../contexts/LoadingContext';
import { crypt } from '../utils/encrypt';
import { IconButton, InputAdornment, Checkbox, FormControlLabel } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Alert from '../modals/Alert';

// Define the schema using yup
const schema = yup.object({
    email: yup.string().email('Invalid email address').required('Email is required'),
    password: yup.string().required('Password is required'),
});

const Login = () => {

    const navigate = useNavigate();
    const { updateUser } = useUser();
    const [isResetModalOpen, setResetModalOpen] = useState(false);
    const { showLoader, hideLoader } = useLoading();
    const [showPassword, setShowPassword] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertContent, setAlertContent] = useState({
        title: '',
        description: '',
        buttons: [],
    });
    const [rememberMe, setRememberMe] = useState(false);

    // Pre-populate form fields from localStorage if available
    useEffect(() => {
        const savedEmail = localStorage.getItem('email');
        const savedPassword = localStorage.getItem('password');
        if (savedEmail && savedPassword) {
            setValue('email', savedEmail);
            setValue('password', savedPassword);
            setRememberMe(true); // Check "Remember Me" checkbox if credentials are saved
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleButtonClick = (value) => {
        setShowAlert(false);
        navigate('/')
    };

    const toggleShowPassword = () => setShowPassword(!showPassword);

    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    const handleOpenResetModal = () => {
        setResetModalOpen(true);
    };

    const handleCloseResetModal = () => {
        setResetModalOpen(false);
    };

    const onSubmit = async (formData) => {
        showLoader();
        const bookingQR = localStorage.getItem('scannedQR');
        const { data, error } = await supabase.auth.signInWithPassword({
            email: formData?.email,
            password: formData?.password
        });

        if (error) {
            showToastFailure(error.message);
            hideLoader();
        } else {
            // Extract user ID from the data object
            const userId = data.user.id;

            // Query the users table to check if the user is a super admin
            const { data: userData, error: userError } = await supabase
                .from('users')
                .select('*')
                .eq('user_id', userId)
                .single();

            hideLoader();

            if (userError) {
                showToastFailure(userError.message);
            } else {
                if (userData.role) {

                    const encrypted_text = crypt("salt", formData.password);
                    localStorage.setItem("cipher", encrypted_text);

                    // If Remember Me is checked, save email and password to localStorage
                    if (rememberMe) {
                        localStorage.setItem('email', formData.email);
                        localStorage.setItem('password', formData.password); // You might want to hash this password in production
                    } else {
                        localStorage.removeItem('email');
                        localStorage.removeItem('password');
                    }

                    updateUser({
                        id: userId,
                        email: formData?.email,
                        token: data.session.access_token,
                        fullName: userData.first_name + userData.last_name,
                        phone: userData.phone
                    });

                    if (bookingQR) {
                        navigate(`/pass`);
                    } else {
                        setAlertContent({
                            title: 'Login Success',
                            description: 'please scan booking QR now to continue booking!',
                            buttons: [{ label: 'OK', value: 'ok' }],
                        });
                        setShowAlert(true);
                    }
                } else {
                    showToastFailure('Not authorized to login');
                }
            }
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Email address"
                            fullWidth
                            margin="normal"
                            error={!!errors.email}
                            helperText={errors.email?.message}
                        />
                    )}
                />

                <Controller
                    name="password"
                    defaultValue=''
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            margin="normal"
                            error={!!errors.password}
                            helperText={errors.password?.message}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={toggleShowPassword}
                                            onMouseDown={(e) => e.preventDefault()}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                            color="default"
                            sx={{
                                '&.Mui-checked': {
                                    color: '#756394', // This will change the checkbox color when checked
                                },
                            }}
                        />
                    }
                    label="Remember Me"
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                        backgroundColor: '#756394',
                        color: '#FFFFFF',
                        marginTop: '10px',
                    }}
                >
                    Sign in
                </Button>
            </form>

            <Button
                onClick={handleOpenResetModal}
                sx={{
                    marginTop: '10px',
                    color: '#756394',
                    display: 'block',
                    marginLeft: 'auto',
                }}
            >
                Forgot Password?
            </Button>

            <PasswordResetModal
                open={isResetModalOpen}
                handleClose={handleCloseResetModal}
            />

            {showAlert && (
                <Alert
                    title={alertContent.title}
                    description={alertContent.description}
                    buttons={alertContent.buttons}
                    onButtonClick={handleButtonClick}
                    onClose={handleButtonClick}
                />
            )}

        </>
    );
};

export default Login;
