import React, { useEffect, useState } from 'react';
import moment from 'moment';
import FeedbackModal from '../components/FeedbackModal';
import orderService from '../services/ordersService'
import { useUser } from '../contexts/UserContext';
import { useLoading } from '../contexts/LoadingContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { red } from '@mui/material/colors';
import { formatIndianCurrency } from '../utils/formatIndianCurrency';

const { getOrderByUserId } = orderService;

const AccessActivePassScreen = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useUser();
  const { showLoader, hideLoader } = useLoading();
  const [history, setHistory] = useState([]);
  const [timers, setTimers] = useState({});
  const [isEndGame, setIsGameEnd] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedTimers = history?.reduce((acc, activePass) => {
        const remainingTime = calculateRemainingTime(activePass?.created_at, activePass?.pass_details?.max_hours_allowed);
        const completionTime = calculateCompletionTime(activePass?.created_at, activePass?.pass_details?.max_hours_allowed);

        acc[activePass.id] = {
          remainingTime,
          completionTime,
        };

        return acc;
      }, {});

      setTimers(updatedTimers);
    }, 1000);

    return () => clearInterval(interval);
  }, [history]);



  useEffect(() => {
    const fetchHistory = async () => {
      if (user?.id) {
        const fetchedPasses = await getOrderByUserId(user?.id, showLoader, hideLoader);
        setHistory(fetchedPasses);
      }
    };
    fetchHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);


  const handleFeedbackClick = (pass, isGameEnd) => {
    setIsGameEnd(isGameEnd)
    setSelectedOrder(pass);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  const calculateRemainingTime = (createdAt, maxHoursAllowed) => {
    const now = moment();
    const endTime = moment(createdAt).add(maxHoursAllowed, 'hours');
    const duration = moment.duration(endTime.diff(now));

    if (duration.asSeconds() <= 0) {
      return 'Game Completed';
    }

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const calculateCompletionTime = (createdAt, maxHoursAllowed) => {
    return moment(createdAt).add(maxHoursAllowed, 'hours').format('DD-MM-YYYY HH:mm:ss');
  };

  return (

    <div className="flex flex-col items-center min-h-screen w-full bg-gray-100 px-4 py-8">
      <div className="w-full max-w-2xl">
        {history?.length > 0 ? (
          <div className="grid grid-cols-1 gap-4 w-full">
            {history.map((activePass, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-md flex flex-col space-y-3 hover:shadow-lg transition-shadow duration-300"
              >
                {/* Pass Title and End Session Button */}
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold text-gray-900 truncate">
                    {activePass?.pass_details?.title}
                  </h3>

                  {activePass?.order_confirmed && activePass?.sessions && activePass?.sessions[0]?.game_status !== 'completed' &&
                    // Check if current time is within allowed max hours
                    moment().isBefore(moment(activePass?.sessions[0]?.created_at).add(activePass?.pass_details?.max_hours_allowed, 'hours')) && (
                      <button
                        className="bg-customPurple text-white font-medium text-sm px-3 py-2 rounded-md hover:bg-blue-700 transition duration-200"
                        onClick={() => handleFeedbackClick(activePass, true)}
                      >
                        End Session
                      </button>
                    )}
                </div>

                {/* Timer / Game Status */}
                {activePass?.order_confirmed && activePass.sessions && activePass.sessions?.length > 0 &&

                  <div className="mt-2">
                    {activePass?.sessions[0]?.game_status !== 'completed' ? (
                      <div className="bg-gray-100 p-3 rounded-lg">
                        <span
                          className={`block text-base font-semibold ${timers[activePass.id]?.remainingTime === 'Game Completed'
                            ? 'text-red-600'
                            : 'text-green-600'
                            }`}
                        >
                          {timers[activePass.id]?.remainingTime}
                        </span>
                        <span className="block mt-1 text-sm text-gray-600">
                          Completion Time: {timers[activePass.id]?.completionTime}
                        </span>
                      </div>
                    ) : (
                      <span className="text-base text-red-600 font-semibold">
                        Game Completed
                      </span>
                    )}
                  </div>

                }

                {/* Pass Description */}
                <p className="text-gray-600 text-sm mt-3 truncate">
                  {activePass?.pass_details?.description}
                </p>

                {/* Order and Payment Details */}
                <div className="grid grid-cols-2 gap-4 text-gray-700 text-sm mt-4">
                  <div>
                    <p className="mb-2">
                      <strong>Paid Amount:</strong> ₹ {formatIndianCurrency((activePass?.amount || 0) + (activePass?.gst_amount || 0))}
                    </p>
                    <p className="mb-2">
                      <strong>Tickets:</strong> {activePass?.no_of_ticket_purchased}
                    </p>
                  </div>
                  <div>
                    {activePass?.order_confirmed && (
                      <p className="flex items-center mb-2">
                        <strong className="mr-2">Payment Status:</strong>
                        {activePass?.payment_status ? (
                          <CheckCircleIcon style={{ color: 'green' }} />
                        ) : (
                          <CancelIcon style={{ color: 'red' }} />
                        )}
                      </p>
                    )}

                    <p className="flex items-center mb-2">
                      <strong className="mr-2">Order Confirmed:</strong>
                      {activePass?.order_confirmed ? (
                        <CheckCircleIcon style={{ color: 'green' }} />
                      ) : (
                        <CancelIcon style={{ color: red[500] }} />
                      )}
                    </p>

                    <p className="mb-2">
                      <strong>Payment Mode:</strong> {activePass?.payment_mode}
                    </p>
                  </div>
                </div>


                <div>
                  <p className="mb"><strong>Order At:</strong> {activePass?.created_at && moment(activePass.created_at).format('DD-MM-YYYY HH:mm')}</p>
                </div>

                {/* Feedback Section */}
                {activePass?.order_confirmed &&
                  <p className="mt-2 text-gray-700 text-sm truncate">
                    <strong>Feedback Message:</strong> {activePass?.sessions[0]?.feedback || 'No feedback provided'}
                  </p>
                }
                {activePass?.order_confirmed && <p className="mt-2 text-gray-700 text-sm truncate">
                  <strong> Games:</strong> {(activePass?.sessions[0]?.feedback_games === 0) ? '-' : activePass?.sessions[0]?.feedback_games || '-'} |
                  <strong> Overall:</strong> {(activePass?.sessions[0]?.feedback_overall === 0) ? '-' : activePass?.sessions[0]?.feedback_overall || '-'} |
                  <strong> Ambiance:</strong> {(activePass?.sessions[0]?.feedback_ambiance === 0) ? '-' : activePass?.sessions[0]?.feedback_ambiance || '-'}
                </p>}
                {activePass?.order_confirmed && activePass?.sessions &&
                  activePass?.sessions[0]?.game_status !== 'completed' &&
                  (!activePass?.sessions[0]?.feedback_ambiance || activePass?.sessions[0]?.feedback_ambiance === 0) &&
                  (!activePass?.sessions[0]?.feedback_games || activePass?.sessions[0]?.feedback_games === 0) &&
                  (!activePass?.sessions[0]?.feedback_overall || activePass?.sessions[0]?.feedback_overall === 0) &&
                  moment().isBefore(moment(activePass?.sessions[0]?.created_at).add(activePass?.pass_details?.max_hours_allowed, 'hours')) && (
                    <button
                      className="bg-customPurple text-white font-medium text-sm px-3 py-2 rounded-md hover:bg-blue-700 transition duration-200"
                      onClick={() => handleFeedbackClick(activePass, false)}
                    >
                      Give Feedback
                    </button>
                  )}
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-500 mt-10">
            <p>No active passes available.</p>
          </div>
        )}
      </div>

      {selectedOrder && (
        <FeedbackModal
          isEndGame={isEndGame}
          order={selectedOrder}
          isOpen={isModalOpen}
          onClose={handleModalClose}
        />
      )}
    </div>


  );
};

export default AccessActivePassScreen;
