import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { supabase } from '../client';
import { showToastFailure, showToastSuccess } from '../services/toast';
import { useLoading } from '../contexts/LoadingContext';

// Define the schema using yup
const schema = yup.object({
    email: yup.string().email('Invalid email address').required('Email is required'),
});

const PasswordResetModal = ({ open, handleClose }) => {
    const { showLoader, hideLoader } = useLoading();
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (formData) => {
        showLoader();

        const { data: existingUser } = await supabase
            .from('users')
            .select('id')
            .or(`email.eq.${formData.email}`)
            .single();
        if (existingUser) {

            const { error } = await supabase.auth.resetPasswordForEmail(formData.email, {
                redirectTo: process.env.REACT_APP_URL + '/reset-password',
            })

            if (error) {
                hideLoader();
                showToastFailure(error.message);
            } else {
                hideLoader();
                showToastSuccess('Password reset email sent. Please check your inbox.');
                handleClose(); // Close the modal after successful submission
            }
        }else{
            showToastFailure('This email is not registered with us!')
            hideLoader()
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="password-reset-modal"
            aria-describedby="password-reset-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Email address"
                                fullWidth
                                margin="normal"
                                error={!!errors.email}
                                helperText={errors.email?.message}
                            />
                        )}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            backgroundColor: '#756394',
                            color: '#FFFFFF',
                            marginTop: '10px',
                        }}
                    >
                        Reset Password
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default PasswordResetModal;
