import React, { useState } from 'react';
import { SwipeableDrawer, Typography, Box, Divider } from '@mui/material';

const BottomSheet = ({ open, onClose, onPaymentSelect }) => {
  const [showCashOption, setShowCashOption] = useState(false);

  const handlePaymentOption = (option) => {
    onPaymentSelect(option); // Pass the selected option to the parent
    onClose(); // Close the BottomSheet
  };

  const handleOtherOption = () => {
    setShowCashOption(true); // Reveal the cash payment option
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}} // No need to handle open here, managed by parent
    >
      <Box
        role="presentation"
        sx={{ padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} // Align to the left
      >
        <Typography variant="h6" sx={{ width: '100%', textAlign: 'left' }}>
          Choose Payment Option
        </Typography>
        <Divider sx={{ width: '100%', marginY: 1 }} />

        {/* Emphasized Pay Online Option */}
        <Typography 
          variant="body1" 
          onClick={() => handlePaymentOption('online')} 
          sx={{ 
            margin: 1, 
            cursor: 'pointer', 
            fontSize: '1.5rem', 
            fontWeight: 'bold', 
            color: '#756394',
            textAlign: 'left', 
            width: '100%' 
          }} // Align to left
        >
          Pay Online
        </Typography>
        <Divider sx={{ width: '100%', marginY: 1 }} />

        {/* Other Payment Option */}
        <Typography 
          variant="body1" 
          onClick={handleOtherOption} 
          sx={{ 
            margin: 1, 
            cursor: 'pointer', 
            fontSize: '1.25rem', 
            color: '#333', 
            textAlign: 'left', 
            width: '100%' 
          }} // Align to left
        >
          Other
        </Typography>

        {/* Show Pay in Cash only after clicking "Other" */}
        {showCashOption && (
          <>
            <Divider sx={{ width: '100%', marginY: 1 }} />
            <Typography 
              variant="body1" 
              onClick={() => handlePaymentOption('cash')} 
              sx={{ 
                margin: '1rem 0 2rem',  // Add more space to push this lower
                cursor: 'pointer', 
                fontSize: '1rem',  // Smaller font size
                color: '#888',  // Lighter color to de-emphasize
                textAlign: 'left', 
                width: '100%' 
              }} // Align to left
            >
              Pay in Cash
            </Typography>
          </>
        )}

        <Divider sx={{ width: '100%', marginY: 1 }} />

        {/* Cancel Option */}
        <Typography 
          variant="body1" 
          onClick={onClose} 
          sx={{ 
            margin: 1, 
            cursor: 'pointer', 
            fontSize: '1.25rem', 
            color: 'red', 
            textAlign: 'left', 
            width: '100%' 
          }} // Align to left
        >
          Cancel
        </Typography>
      </Box>
    </SwipeableDrawer>
  );
};

export default BottomSheet;
