import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../client';
import { showToastFailure } from '../services/toast';
import LandingPage from './Landing';
import PassScreen from './PassScreen';
import PassDetailsList from './PassDetailsScreen';
import Auth from './Auth';
import ResetPassword from './ResetPassword';
import ProfileScreen from './ProfileScreen';
import AccessActivePass from './AccessActivePassScreen'
import RouteGuard from '../hoc/RouteGuard';
import { useUser } from '../contexts/UserContext';
import { useLoading } from '../contexts/LoadingContext';
import ConfirmPayment from './ConfirmPayment';
import AboutUs from './AboutUs';
import TermsConditions from './TermsCondition';
import PrivacyPolicy from './PrivacyPolicy';
import CancellationPolicy from './CancellationPolicy';
import EventDetails from './EventDetails';
import BookedEvents from './BookedEvents';
import Alert from '../modals/Alert';

const AppContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const scannedQR = localStorage.getItem('scannedQR');
  const user = useUser();
  const { user: userData } = user;
  const { showLoader, hideLoader } = useLoading();
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({
    title: '',
    description: '',
    buttons: [],
  });

  const handleButtonClick = (value) => {
    setShowAlert(false);
};

  const closeModal = () => {
    setShowAlert(false);
  }

  const validateQRCode = async (qrCode) => {
    try {
      showLoader();
      let { data: qrResponse, error } = await supabase.rpc('validate_qr', { qr_code: qrCode });

      if (error) {
        setAlertContent({
          title: 'Error',
          description: 'An error occurred while validating the QR code.',
          buttons: [{ label: 'OK', value: 'ok' }],
        });
        setShowAlert(true)
        return;
      }

      if (qrResponse) {
        if (qrResponse?.isValid) {
          if (qrResponse?.data && qrResponse?.data?.is_active && qrResponse?.data?.type === 'general') {

            // Calculate the expiration time (4 AM the next day)
            const now = new Date();
            const expirationTime = new Date();
            expirationTime.setHours(4, 0, 0, 0);
            if (now > expirationTime) {
              expirationTime.setDate(expirationTime.getDate() + 1);
            }
            const expirationTimestamp = expirationTime.getTime();

            // Store the QR code and expiration time in localStorage
            localStorage.setItem('scannedQR', qrCode);
            localStorage.setItem('expirationTime', expirationTimestamp.toString());
            navigate('/pass');
          } else if (qrResponse?.data && qrResponse?.data?.is_active && qrResponse?.data?.type === 'new_registration') {
            navigate('/auth');
          }
          else {
            setAlertContent({
              title: 'QR Code Status',
              description: 'The QR code is valid but currently inactive. Please check with the store for more information.',
              buttons: [{ label: 'OK', value: 'ok' }],
            });
            setShowAlert(true);
          }
        } else {
          setAlertContent({
            title: 'Invalid QR Code',
            description: 'The QR code you scanned is not recognized. Please ensure it is correct and try again.',
            buttons: [{ label: 'OK', value: 'ok' }],
          });
          setShowAlert(true)
        }
      } else {
        showToastFailure('Unexpected response from server.');
      }
    } catch (err) {
      showToastFailure('An unexpected error occurred.');
    } finally {
      hideLoader();
    }
  };


  useEffect(() => {
    // Retrieve the QR code and expiration time from localStorage
    const scannedQR = localStorage.getItem('scannedQR');
    const expirationTime = localStorage.getItem('expirationTime');

    if (scannedQR && expirationTime) {
      const now = new Date().getTime();
      const expirationTimestamp = parseInt(expirationTime, 10);

      if (now > expirationTimestamp) {
        // Clear the stored QR code if the current time is past the expiration time
        localStorage.removeItem('scannedQR');
        localStorage.removeItem('expirationTime');
        navigate('/');
      }
    }

    // Function to parse query parameters
    const queryParams = new URLSearchParams(location.search);
    const qrCode = queryParams.get('q');

    if (qrCode) {
      validateQRCode(qrCode);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
  
    <>
    <Routes>
      <Route
        path="/"
        element={
          <RouteGuard
            condition={!scannedQR}
            redirectTo={scannedQR ? "/pass" : "/"}
          >
            <LandingPage />
          </RouteGuard>
        }
      />
      <Route
        path="/pass"
        element={
          <RouteGuard
            condition={scannedQR}
            redirectTo="/"
          >
            <PassScreen />
          </RouteGuard>
        }
      />

      <Route
        path="/tickets"
        element={
          <RouteGuard
            condition={scannedQR}
            redirectTo={user ? "/" : "/auth"}
          >
            <PassDetailsList />
          </RouteGuard>
        }
      />

      <Route
        path="/active-pass"
        element={
          <RouteGuard
            condition={user && scannedQR}
            redirectTo={user ? "/" : "/auth"}
          >
            <AccessActivePass />
          </RouteGuard>
        }
      />

      <Route
        path="/event-details"
        element={
          <RouteGuard
            condition={user && scannedQR}
            redirectTo={user ? "/" : "/auth"}
          >
            <EventDetails />
          </RouteGuard>
        }
      />

      <Route
        path="/booked-events"
        element={
          <RouteGuard
            condition={user && scannedQR}
            redirectTo={user ? "/" : "/auth"}
          >
            <BookedEvents />
          </RouteGuard>
        }
      /> 

      <Route
        path="/confirm-payment"
        element={
          <RouteGuard
            condition={user && scannedQR}
            redirectTo={user ? "/" : "/auth"}>
            <ConfirmPayment />
          </RouteGuard>
        }
      />

      <Route
        path="/auth"
        element={
          <RouteGuard
          condition={!userData} // Only show auth if user is not logged in
          redirectTo={userData ? "/pass" : "/auth"} // Redirect to Pass if logged in
          >
            <Auth />
          </RouteGuard>
        }
      />

      <Route
        path="/reset-password"
        element={<ResetPassword />}
      />
      <Route
        path="/profile"
        element={
          <RouteGuard
            condition={user}
            redirectTo={user ? "/" : "/auth"}
          >
            <ProfileScreen />
          </RouteGuard>
        }
      />

      <Route
        path="/about-us"
        element={
          <RouteGuard
            condition={true}
            redirectTo={"/about-us"}
          >
            <AboutUs />
          </RouteGuard>
        }
      />

      <Route
        path="/terms-and-conditions"
        element={
          <RouteGuard
            condition={true}
            redirectTo={"/terms-and-conditions"}
          >
            <TermsConditions />
          </RouteGuard>
        }
      />

      <Route
        path="/privacy-policy"
        element={
          <RouteGuard
            condition={true}
            redirectTo={"/privacy-policy"}
          >
            <PrivacyPolicy />
          </RouteGuard>
        }
      />

      <Route
        path="/cancellation-policy"
        element={
          <RouteGuard
            condition={true}
            redirectTo={"/cancellation-policy"}
          >
            <CancellationPolicy />
          </RouteGuard>
        }
      />

      {scannedQR ? (
        <Route path="*" element={<PassScreen />} />
      ) : (
        <Route path="*" element={<LandingPage />} />
      )}
    </Routes>

  
    {showAlert && (
                <Alert
                    title={alertContent.title}
                    description={alertContent.description}
                    buttons={alertContent.buttons}
                    onButtonClick={handleButtonClick}
                    onClose={closeModal} 
                />
            )}
    </>
  );
};

export default AppContent;
