import axios from "axios";
import { supabase } from "../client"
import { getFormattedDate } from "../utils/formattedDate";
import { showToastFailure, showToastSuccess } from "./toast"

export const getEvents = async (startLoading, stopLoading) => {
  try {
    startLoading()
    const now = new Date();
    
    // Query the database for events that haven't ended
    const { data, error } = await supabase
      .from('events')
      .select('*')
      .eq('is_active', true)
      .gte('event_end_time', getFormattedDate(now))
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching events:', error);
    }

    if (error) {
      throw error
    }

    return data
  } catch (error) {
    showToastFailure(error.message)
    return []
  } finally {
    stopLoading()
  }
}

export const createEventsOrder = async (orderData, startLoader, stopLoader) => {
  const { event_id, totalPayableAmount, totalGst, selectedTickets, user_id, payment_id, payment_status } = orderData;

  try {
    startLoader();

    // Insert query for the orders table
    const { error } = await supabase
      .from('order_events')
      .insert([
        {
          user_id,
          event_id,
          amount: Number(totalPayableAmount),
          gst_amount:totalGst,
          payment_status,
          created_at: getFormattedDate(new Date()),
          no_of_ticket_purchased: selectedTickets,
          payment_id,
        }
      ])
      .select();

    if (error) {
      throw new Error('Failed to create order');
    } else {
      showToastSuccess('Your payment was successful! Your event has been purchased. Please visit the "Access your events" section to check your details.');
    }

    if (payment_status) {
      await calculateRemainingTickets(event_id, selectedTickets);
    }

  } catch (error) {
    stopLoader();
    showToastFailure('Please contact the store if the payment is already processed, there is a technical issue');
  }finally{
    stopLoader();
  }
};

const calculateRemainingTickets = async (eventId, no_of_ticket_purchased) => {

  const { data } = await supabase
    .from('events')
    .select('no_of_tickets')
    .eq('id', eventId)


  if (data && data.length && data[0].no_of_tickets >= no_of_ticket_purchased) {
    const remainingTickets = data[0].no_of_tickets - no_of_ticket_purchased;

    // Prepare the update payload
    const updatePayload = { no_of_tickets: remainingTickets };

    // If remaining tickets are 0, mark the event as inactive
    if (remainingTickets === 0) {
      updatePayload.is_active = false;
    }

    // Update the remaining tickets (and is_active if needed) in the database
    const { error: updateError } = await supabase
      .from('events')
      .update(updatePayload)
      .eq('id', eventId);

    if (updateError) {
      console.error('Error updating event:', updateError);
      return;
    }
  }
}

export const getCurrentAvailableTickets = async (eventId) => {
  const { data } = await supabase
  .from('events')
  .select('no_of_tickets')
  .eq('id', eventId)
  return data;

}

export const getCustomerBookedEventsByUserId = async (userId, startLoader, stopLoader) => {
  try {
    startLoader();
    // Fetch orders with pass details and session data
    const { data, error } = await supabase
      .from('order_events')
      .select(`
          *,
          events:event_id (
            *
          )
        `)
      .eq('user_id', userId)
      .order('created_at', { ascending: false });


    if (error) {
      throw new Error('Failed to fetch orders');
    }

    return data;
  } catch (error) {
    showToastFailure('Failed to fetch orders');
    console.error(error);
    return [];
  } finally {
    stopLoader();
  }
};

export const createRazorPayEventOrder = async (totalAmt, userId, serviceId, startLoader, hideLoader) => {
  try {
    startLoader();
    const response = await axios.post(process.env.REACT_APP_API+'/create-order', {
      amount: totalAmt,
      currency: 'INR',
      notes: {
        user_id: userId,
        eventId: serviceId
      },
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const { order } = response.data;
    return order;
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 2xx
      console.error('Error response from server:', error.response.data);
    } else if (error.request) {
      // Request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something went wrong in setting up the request
      console.error('Error setting up request:', error.message);
    }
    console.error('Failed to create order:', error);
    return false;
  } finally {
    hideLoader();
  }
};