import React from 'react';

const PassCard = ({ pass, onPassClick, bgColor = 'bg-white', textColor = 'text-gray-800', buttonBgColor = 'bg-customPurple', buttonTextColor = 'text-white', btnText }) => {
  return (
    <div className={`p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ${bgColor}`}>
      <div className="flex justify-between items-start">
        <div className="flex-1">
          <h3 className={`text-lg font-semibold ${textColor}`}>{pass?.name}</h3>
          <p className={`text-gray-600 text-sm ${textColor} break-words`}>
            {pass?.description}
          </p>
        </div>
        <button
          className={`ml-4 ${buttonBgColor} ${buttonTextColor} font-medium px-3 py-1 rounded-md hover:bg-blue-700 transition duration-200`}
          onClick={() => onPassClick(pass)}
        >
          {btnText}
        </button>
      </div>
    </div>
  );
};

export default PassCard;
