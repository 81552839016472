import React from 'react';
import { Link } from 'react-router-dom';

const Landing = () => {
  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-50 text-gray-800 px-4 py-8">

      {/* Header: Instructional Text */}
      <div className="text-center mb-10 p-8 bg-white rounded-lg shadow-md">
        <h1 className="text-3xl font-bold text-gray-900 mb-3">Continue Your Booking</h1>
        <b className="text-lg text-gray-700 mb-2">
          Open your camera and scan the QR code at <strong>Bombay Board Games Club</strong> to proceed.
        </b>
        <p className="text-md text-gray-600">
          If you need assistance, please ask our staff at the store.
        </p>
      </div>

      {/* Contact Information Card */}
      <div className="text-center mb-10 p-8 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-900 mb-3">Need Help?</h2>
        <p className="text-lg text-gray-800 flex items-center justify-center mb-2">
          <i className="fas fa-phone-alt mr-2 text-customPurple-600"></i>
          <strong>+91 98200 02546</strong>
        </p>
        <p className="text-lg text-gray-800 flex items-center justify-center">
          <i className="fas fa-map-marker-alt mr-2 text-customPurple-600"></i>
          <span>Bombay Board Game Club, 206, Man Excellenza,<br /> SV Road, Irla, Vile Parle West, Mumbai</span>
        </p>
      </div>

      <div className="text-center mb-10 p-8 bg-white rounded-lg shadow-md w-full max-w-md mx-auto">
        <h3 className="text-2xl font-semibold text-gray-800 mb-5">Quick Links</h3>
        <div className="space-y-4">
          <Link to="/about-us" className="block text-customPurple hover:underline text-md">
            About Us
          </Link>
          <Link to="/terms-and-conditions" className="block text-customPurple hover:underline text-md">
            Terms & Conditions
          </Link>
          <Link to="/privacy-policy" className="block text-customPurple hover:underline text-md">
            Privacy Policy
          </Link>
          <Link to="/cancellation-policy" className="block text-customPurple hover:underline text-md">
            Cancellation/Refund Policy
          </Link>
        </div>
      </div>


    </div>
  );
};

export default Landing;
