import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useUser } from '../contexts/UserContext';
import { useLoading } from '../contexts/LoadingContext';
import { getCustomerBookedEventsByUserId } from '../services/eventsService';

const BookedEvents = () => {
  const { user } = useUser();
  const { showLoader, hideLoader } = useLoading();
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const fetchHistory = async () => {
      if (user?.id) {
        const fetchedPasses = await getCustomerBookedEventsByUserId(user?.id, showLoader, hideLoader);
        setHistory(fetchedPasses);
      }
    };
    fetchHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);


  return (
    <div className="flex flex-col items-center min-h-screen w-full bg-gray-100 px-6 py-10">
    <div className="w-full max-w-lg">
      {history?.length > 0 ? (
        <div className="grid grid-cols-1 gap-6 w-full">
          {history.map((activePass, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md flex flex-col space-y-4 hover:shadow-xl transition-all duration-300 border-t-4 border-customPurple"
            >
              <div className="flex items-center justify-between">
                <h3 className="text-2xl font-semibold text-customPurple">{activePass?.events?.name}</h3>
                <span className="text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-lg">
                  {moment(activePass?.events?.event_start_date_time).format('DD-MM-YYYY')}
                </span>
              </div>
  
              <p className="text-gray-700 text-md">{activePass?.events?.description}</p>
              
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="text-gray-600 text-sm">
                  <strong>Event Start Date Time:</strong> {moment(activePass?.events?.event_start_date_time).format('DD-MM-YYYY HH:mm')} 
                </p>
                <p className="text-gray-600 text-sm"> 
                <strong>Event End Date Time:</strong>   {moment(activePass?.events?.event_end_time).format('DD-MM-YYYY HH:mm')}
                </p>
                <p className="text-gray-600 text-sm">
                  <strong>Order Placed:</strong> {moment(activePass?.created_at).format('DD-MM-YYYY HH:mm')}
                </p>
              </div>
  
              <div className="text-gray-700 text-md space-y-2 bg-white rounded-lg p-4 border border-gray-200">
                <p className="flex justify-between">
                  <strong>Amount:</strong> <span>{activePass?.amount}</span>
                </p>
                <p className="flex justify-between">
                  <strong>GST:</strong> <span>{activePass?.gst_amount}</span>
                </p>
                <p className="flex justify-between">
                  <strong>Tickets Purchased:</strong> <span>{activePass?.no_of_ticket_purchased}</span>
                </p>
                <p className="flex justify-between">
                  <strong>Payment Status:</strong> <span className={`${activePass?.payment_status ? 'text-green-600' : 'text-red-600'}`}>{activePass?.payment_status ? 'Success' : 'Failed'}</span>
                </p>
                <p className="flex justify-between">
                  <strong>Payment ID:</strong> <span>{activePass?.payment_id}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center text-gray-500">
          <p>No purchased events available currently.</p>
        </div>
      )}
    </div>
  </div>
  
  );
};

export default BookedEvents;
