import React from 'react';
import { useUser } from '../contexts/UserContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
const Header = () => {

    const { user } = useUser();
    const navigate = useNavigate();

    const handleProfileClick = () => {
        navigate('/profile')
    };

    const handleHomeClick = () => {
        window.location.href = process.env.REACT_APP_URL; // Redirect to the URL
    };

    return (
        <header className="bg-customPurple shadow-md py-4">
            <div className="container mx-auto flex items-center justify-between px-4">
                {/* Logo */}
                <div className="flex items-center">

                    <IconButton
                        onClick={handleHomeClick}
                        className="flex items-center"
                        style={{ backgroundColor: 'transparent' }} // Optional: Make background transparent
                    >
                        <HomeIcon style={{ color: 'white', fontSize: '40px' }} /> {/* Adjust size as needed */}
                    </IconButton>
                    <span className="text-base text-white font-bold">Bombay Board Games Club</span>

                </div>

                {/* Profile Icon and Text */}
                {user && <div className="flex flex-col items-center cursor-pointer" onClick={handleProfileClick}>
                    <AccountCircleIcon className="text-white text-3xl mb-1 hover:text-gray-200 transition duration-300" />
                    <span className="text-white text-sm whitespace-nowrap">Profile</span>
                </div>}
            </div>
        </header>
    );
};

export default Header;
