import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const BannerCarousel = ({images}) => {

  return (
    <Carousel
      autoPlay
      infiniteLoop
      showArrows={true} 
      interval={4000}
      showThumbs={false}
      showStatus={false}
    >
      {images?.map((banner, index) => (
        <div key={index}>
          <img src={banner} alt={`Banner ${index}`} />
        </div>
      ))}
    </Carousel>
  );
};

export default BannerCarousel;
